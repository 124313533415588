<template>
  <Layout :tituloPagina="'Network | Perfiles de velocidad | ' +(modo == 'nuevo' ? 'Nuevo' : 'Edición')">
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">
              {{ modo == 'nuevo' ? 'Nuevo' : '' }}
              {{ modo == 'edicion' ? 'Edición de' : '' }}
              perfil de velocidad
            </h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="modo == 'nuevo' ? guardar() : actualizar()"
                      v-if="!bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="cerrar()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row align-items-center">
              <label class="col-md-2 ">
                Nombre
              </label>
              <div class="col-md-10">
                <input
                  class="form-control"
                  placeholder="Nombre del perfil"
                  ref="nombre"
                  v-model="perfil.nombre"
                />
              </div>
            </div>

            <br>

            <div class="row">
              <div class="col-md-6">
                <label for="">Velocidad de carga</label>
                <sup class="text-info" v-show="perfil.carga == 0">
                  0 indica velocidad ilimitada
                </sup>
                <div class="input-group">
                  <input
                    style="width: 75%;"
                    type="number"
                    class="form-control text-right"
                    min="0"
                    ref="carga"
                    placeholder="000"
                    v-model="perfil.carga"
                    @change="perfil.carga = valorValido(perfil.carga)"
                  />
                  <select
                    style="width: 25%;"
                    class="form-select"
                    ref="unidadDescarga"
                    v-model="perfil.unidad_carga"
                  >
                    <option value="k">Kbps</option>
                    <option value="M">Mbps</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Velocidad de descarga</label>
                <sup class="text-info" v-show="perfil.descarga == 0">
                  0 indica velocidad ilimitada
                </sup>
                <div class="input-group">
                  <input
                    style="width: 75%;"
                    type="number"
                    class="form-control text-right"
                    min="0"
                    ref="descarga"
                    placeholder="000"
                    v-model="perfil.descarga"
                    @change="perfil.descarga = valorValido(perfil.descarga)"
                  />
                  <select
                    style="width: 25%;"
                    class="form-select"
                    ref="unidadDescarga"
                    v-model="perfil.unidad_descarga"
                  >
                    <option value="k">Kbps</option>
                    <option value="M">Mbps</option>
                  </select>
                </div>
              </div>
            </div>

            <br>

            <div class="row align-items-center">
              <label class="col-md-2">Estado</label>
              <div class="col-md-2">
                <div class="form-check form-switch form-switch-lg mb-3">
                  <input
                    :checked="perfil.activo"
                    @change="perfil.activo = !perfil.activo"
                    class="form-check-input"
                    type="checkbox"
                    id="banderaPerfilActivo"
                  />
                  <label
                    class="form-check-label"
                    for="banderaPerfilActivo"
                  >
                    {{!activo ? 'Activos' : 'Inactivos'}}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-right">
          <div class="btn-group">
            <button @click="atras()" class="btn btn-secondary">
              <i class="mdi mdi-chevron-left"></i>
              Atras
            </button>
  
            <button
              class="btn btn-success"
              @click="modo == 'nuevo' ? guardar() : actualizar()"
              :disabled="bandera_spinner"
            >
              <i
                class="mdi"
                :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
              ></i>
              {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main.vue"
import PerfilVelocidadCpesSrv from '@/services/PerfilVelocidadCpesSrv.js'
export default {
  name: 'EdicionPerfilVelocidad',
  components: {
    Layout
  },
  data() {
    return {
      modo: 'nuevo',
      perfil: {
        nombre: '',
        carga: 0,
        descarga: 0,
        activo: 1,
        unidad_carga: 'k',
        unidad_descarga: 'k'
      },
      perfilIni: {
        nombre: '',
        carga: 0,
        descarga: 0,
        activo: 1,
        unidad_carga: 'k',
        unidad_descarga: 'k'
      },
      bandera_spinner: false
    }
  },

  created() {
    var self = this

    Object.assign(self.perfilIni, self.perfil)

    // Modo
    if (this.$route.path.indexOf('nuevo') >= 0) self.modo = 'nuevo'
    else self.modo = 'edicion'

    // Cargas iniciales
    if (self.modo == 'edicion') self.cargarPerfil()
  },

  methods: {
    actualizar: function() {
      var self = this
      var perfil = Object.assign({}, this.perfil)

      self.bandera_spinner = true

      if(perfil.nombre == '' || perfil.nombre == null) {
        iu.msg.warning('Es necesario un nombre para el perfil de velocidad')
        self.$refs.nombre.select()
        self.bandera_spinner = false
        return
      }

      if (perfil.unidad_carga == 'k' && perfil.carga > 4294967) {
        iu.msg.warning('Es necesario seleccionar un valor menor que 4294967')
        self.$refs.carga.select()
        self.bandera_spinner = false
        return
      } else if (perfil.unidad_carga == 'M' && perfil.carga > 4294) {
        iu.msg.warning('Es necesario seleccionar un valor menor que 4294')
        self.$refs.carga.select()
        self.bandera_spinner = false
        return
      }

      if (perfil.unidad_descarga == 'k' && perfil.descarga > 4294967) {
        iu.msg.warning('Es necesario seleccionar un valor menor a 4294967')
        self.$refs.descarga.select()
        self.bandera_spinner = false
        return
      } else if (perfil.unidad_descarga == 'M' && perfil.descarga > 4294) {
        iu.msg.warning('Es necesario seleccionar un valor menor a 4294')
        self.$refs.descarga.select()
        self.bandera_spinner = false
        return
      }
      
      PerfilVelocidadCpesSrv.actualizar(perfil).then(response => {
        iu.msg.success('Se actualizó correctamente')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    atras: function() {
      this.$router.go(-1)
    },

    cargarPerfil: function() {
      var self = this

      PerfilVelocidadCpesSrv.perfilJSON(self.$route.params.id).then(response => {
        self.perfil = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el perfil de velocidad'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cerrar: function() {
      this.atras()
    },

    guardar: function() {
      var self = this
      var perfil = Object.assign({}, this.perfil)

      self.bandera_spinner = true

      if(perfil.nombre == '' || perfil.nombre == null) {
        iu.msg.warning('Es necesario un nombre para el perfil de velocidad')
        self.$refs.nombre.select()
        self.bandera_spinner = false
        return
      }

      if (perfil.unidad_carga == 'k' && perfil.carga > 4294967) {
        iu.msg.warning('Es necesario seleccionar un valor menor que 4294967')
        self.$refs.carga.select()
        self.bandera_spinner = false
        return
      } else if (perfil.unidad_carga == 'M' && perfil.carga > 4294) {
        iu.msg.warning('Es necesario seleccionar un valor menor que 4294')
        self.$refs.carga.select()
        self.bandera_spinner = false
        return
      }

      if (perfil.unidad_descarga == 'k' && perfil.descarga > 4294967) {
        iu.msg.warning('Es necesario seleccionar un valor menor a 4294967')
        self.$refs.descarga.select()
        self.bandera_spinner = false
        return
      } else if (perfil.unidad_descarga == 'M' && perfil.descarga > 4294) {
        iu.msg.warning('Es necesario seleccionar un valor menor a 4294')
        self.$refs.descarga.select()
        self.bandera_spinner = false
        return
      }

      PerfilVelocidadCpesSrv.guardar(perfil).then(response => {
        iu.msg.success('Se guardó el perfil')
        Object.assign(self.perfil, self.perfilIni)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo guardar el perfil'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    valorValido: function(valor) {
      if (isNaN(valor) || valor < 0) {
        iu.msg.warning('Es necesario valores positivos no menores a 0')
        return 0
      }

      valor = parseFloat(valor)

      if (valor != valor.toFixed(3)) {
        iu.msg.warning('Solo se permite una precisión de 3 decimales')
        return valor.toFixed(3)
      }

      return valor
    }
  },
}
</script>

<style scoped>
.form-check-input:checked {
  background-color: #63AD6F;
  border-color: #63AD6F;
}
</style>